.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

body {
  background-color: #bcb6a7;
}

.App-header {
  min-height: 15vh;
  max-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  /*border: solid 3px lawngreen;*/
}

.App-info_block {
  margin: 5vh;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  /*min-height: 40vh;*/
  /*max-height: 65vh;*/
  height: 40vh;
  /*border: solid 3px firebrick;*/
}

.info-block_grammar-rule {
  display: block;
  /*position: absolute;*/
  text-align: center;
  justify-content: center;
  /*background-color: white;*/
  /*top: 5px;*/
  /*left: 8%;*/
  overflow: auto;
  height: 75%;
  width: 80%;
  max-width: 900px;
  margin: 2%;
  /*border: solid 3px white;*/
  /*border-radius: 5px;*/
}

.info-block_button {
  display: flex;
  flex-direction: column;
  height: 10%;
  width: 80%;
  margin-top: 5px;
  /*border: solid 2px palevioletred;*/
  align-items: center;
  position: absolute;
  bottom: 5px;
  left: 10%;
}

.button {
  border-radius: 10px;
  min-height: 40px;
  height: 100%;
  /*width: 50%;*/
  min-width: 200px;
  max-width: 250px;
  background-color: gray;
  color: black;
}

.button:hover {
  background-color: black;
  color: #bcb6a7;
}

.App-footer {
  /*border: solid 1px teal;*/
  min-height: 3vh;
  max-height: 5vh;
  display: block;
  position: fixed;
  bottom: 0;
  width: 100%;

}
/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
